export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Companies',
    route: 'companies',
    icon: 'PieChartIcon',
  },
  {
    title: 'Founders',
    route: 'founders',
    icon: 'AwardIcon',
  },
  {
    title: 'Reviews',
    route: 'reviews',
    icon: 'PlayCircleIcon',
  },
  {
    title: 'Contacts',
    route: 'contacts',
    icon: 'MailIcon',
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Import Data',
        route: 'import-data',
        icon: 'FileIcon',
      },
    ],
  },
]
